html {
    scroll-behavior: smooth;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.half-width {
    border: none;
    height: 1px;
    /* Set the hr color */
    color: #333; /* old IE */
    background-color: #f3ededb7; /* Modern Browsers */
}
.b {
    word-wrap: inherit;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.horizontal-line {
    height: 1px;
    border: 0px solid #d6d6d6;
    border-top-width: 1px;
}

p.target-load-header + div {
    padding-bottom: 1.2rem;
}

div.secret-container {
    border: var(--p-border-width-1) solid var(--p-border-shadow);
    border-radius: var(--p-border-radius-1, 0.25rem);
    display: flex;
    min-height: 2.25rem;
    margin-block: 0.4rem;
    transition: box-shadow var(--p-duration-100) var(--p-ease, cubic-bezier(0.4, 0.22, 0.28, 1));
}

div.secret-container.error {
    background-color: var(--p-surface-critical-subdued, #fff4f4);
    border-color: var(--p-border-critical, #fd5749);
}

div.secret-container:focus-within {
    box-shadow: 0 0 0 0.175rem var(--p-focused, #458fff);
    outline: var(--p-border-width-1) solid var(--p-surface, #ffffff);
}

input.secret-input {
    background: none;
    border: none;
    caret-color: var(--p-text, #202223);
    color: var(--p-text, #202223);
    font-family: inherit;
    font-size: var(--p-font-size-5);
    padding: calc((2.25rem - var(--p-font-line-height-3) - var(--p-space-05)) / 2) var(--p-space-3);
    width: 100%;
}

input.secret-input:focus {
    outline: none;
}

input.secret-input:disabled {
    color: var(--p-text-disabled, #8c9196);
}

button.secret-button {
    background: var(--p-surface, #ffffff);
    border: none;
    border-radius: 0 var(--p-border-radius-1, 0.25rem) var(--p-border-radius-1, 0.25rem) 0;
    color: var(--p-interactive, #006fbb);
    cursor: pointer;
    padding-block: 0.4rem;
    padding-inline: 0.8rem;
}

@media (min-width: 40em) {
    input.secret-input {
        font-size: var(--p-font-size-3);
    }
}

div.product-name-wrapper div {
    max-width: 25rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

div.error-offset-wrapper {
    padding-left: 2rem;
}

div.error-list-offset-wrapper {
    padding-left: 2.5rem;
}

div.section-padding-hack {
    min-height: 1.25rem;
}

img.help-images {
    display: block;
    max-width: 90%;
    height: auto;
    margin-inline: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 1px 15px 0 #00000026;
    filter: blur(0em);
    transition: filter 0.5s;
}

img.loading {
    filter: blur(0.2em);
}

div.underline {
    display: inline;
    text-decoration: underline;
}

div.search-result-wrapper {
    max-width: 95%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

div.product-sync-filter-wrapper,
div.stock-sync-filter-wrapper {
    padding: 16px;
}

div.product-sync-filters,
div.stock-sync-filters {
    display: flex;
}

div.product-sync-filters > div:first-child,
div.stock-sync-filters > div:first-child {
    flex: 1;
}

div.product-sync-filters > div:last-child,
div.stock-sync-filters > div:last-child {
    padding-left: 8px;
}

div.product-sync-query-text,
div.stock-sync-query-text {
    display: flex;
    align-items: center;
    padding-top: 8px;
    padding-left: 8px;
}

div.product-sync-refresh-button,
div.stock-sync-refresh-button {
    margin-left: auto;
}
